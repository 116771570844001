/**
 * Context provider focus on modal functionalities
 * It stores all the modal open state variables and provides two methods
 * showModal("name"): Sets to true  the "name" modal variable
 * hideModal("name"): Sets to false the "name" modal variable
 * Extra information in https://gitlab.com/stefano.g/pathfinder_dev/wikis/Frontend/ModalContext
 */

import React, { createContext } from "react";

export const ModalContext = createContext();

export default class ModalProvider extends React.Component {
  /*
    Method that closes open dialogs that couldn't stay open at the same time
   */
  hideOthers = () => {
    this.setState({
      user_profile: false
    });
  };
  hideModal = name => {
    this.setState({
      [name]: false
    });
  };
  showModal = name => {
    this.hideOthers();
    this.setState({
      [name]: true
    });
  };
  toggleModal = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };
  updateModal = (name, value) => {
    this.setState({
      [name]: value
    });
  };
  setTargetScenario = scenario_id => {
    this.setState({ target_scenario: scenario_id });
  };
  setViewType = mode => {
    this.setState({ viewType: mode });
  };
  setResolutionValid = isValid => {
    this.setState({ resolutionIsValid: isValid });
  };
  setViewMode = mode => {
    this.setState({ viewMode: mode });
  };
  togglePreviewMode = () => {
    if (this.state.viewMode === "full") {
      this.setViewMode("presentation");
    } else {
      this.setViewMode("full");
    }
  };

  getViewType = () => {
    return this.state.viewType;
  };

  setImportedPath = value => {
    this.setState({ importedPath: value });
  };

  handleKeyDown(e) {
    if (e.ctrlKey && e.altKey && e.which === 80) {
      this.togglePreviewMode();
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown.bind(this));
  }
  state = {
    validation: false,
    new_project: false,
    delete_project: false,
    select_project: false,
    new_layer: false,
    new_category: false,
    edit_layers: false,
    buffer_layers: false,
    new_scenario: false,
    open_scenario: false,
    scenario_settings: false,
    export_scenario: false,
    import_scenario: false,
    delete_scenario: false,
    export_results: false,
    generate_report: false,
    layer_statistics: false,
    select_model: false,
    optimal_analytics: false,
    optimal_analytics_path_id: null,
    optimal_analytics_cost_model: null,
    elevation_profile: false,
    resistance_map: false,
    corridor_map: false,
    optimal_path: false,
    import_corridor: false,
    hybrid_view: false,
    street_view: false,
    topographic_view: false,
    show_legend: false,
    center_map: false,
    ddd_view: false,
    full_screen: false,
    take_screenshot: false,
    help_center: false,
    report_a_bug: false,
    user_profile: false,
    user_profile_tab: "baselayers",
    geoprocess_dialog: false,
    add_scenario_to_catalog: false,
    load_scenario_from_catalog: false,
    monetary_cost: false,
    monetary_cost_path_id: null,
    import_path: false,
    resolutionIsValid: true,
    viewMode: "full",
    identifyResistanceCoordinates: null,
    routingModel: "",
    importedPath: null,
    postProcFunction: "",
    // Functions
    registerModal: this.registerModal,
    showModal: this.showModal,
    hideModal: this.hideModal,
    toggleModal: this.toggleModal,
    updateModal: this.updateModal,
    setTargetScenario: this.setTargetScenario,
    setViewType: this.setViewType,
    getViewType: this.getViewType,
    setResolutionValid: this.setResolutionValid,
    togglePreviewMode: this.togglePreviewMode,
    setImportedPath: this.setImportedPath
  };

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}

export const ModalConsumer = ModalContext.Consumer;
